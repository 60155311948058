/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {FC} from 'react'
import {
  getWallList,
  NewPost,
  uploadfile,
  LIKE_API,
} from '../../modules/wall/redux/WallCRUD'
import {IWallModel, Ipostcontent} from '../../modules/wall/WallModel'
import {UserModel} from '../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {KTSVG} from '../../../_ecadme/helpers'
import {Link} from 'react-router-dom'

interface IState {
  loading: boolean
  Wall: IWallModel[]
  errorMessage: string
  commentwall:string
}
interface IProps {}

const Wall: FC<IProps> = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  let timeOffset: number = new Date().getTimezoneOffset()
  let startIndex = 0
  let limit = 20
  let VISIBILITY_EVERYONE = 1
  let VISIBILITY_CLASSMATE = 2
  let VISIBILITY_YEARGROUP = 3
  let EXPIRY_1DAY = 1
  let EXPIRY_1WEEK = 2
  let EXPIRY_1MONTH = 3
  let EXPIRY_1YEAR = 4
  let EXPIRY_NEVER = 5
  let entityType = 6

  let strFileArray: string = ''
  let lastUploadedFile: string = ''
  let CurrentVisibility = VISIBILITY_EVERYONE
  let currentExpiry = EXPIRY_NEVER
  let lstPostcontent: Ipostcontent
  // let commentwall: string = ''

  let [state, setState] = useState<IState>({
    loading: false,
    Wall: [] as IWallModel[],
    errorMessage: '',
    commentwall:""
  })

  useEffect(() => {
    setState({...state, loading: true})

    setTimeout(() => {
      fetchAllWall()
    }, 100)
  }, [])

  function getAgo(dt: string) {
    try {
      let dateVal: Date = new Date(dt.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
      let postTime: number = dateVal.getTime()
      let curentDate: Date = new Date()
      let CurrentTime: number = curentDate.getTime()
      let secondDifference: number = (CurrentTime - postTime) / 1000
      if (secondDifference >= 365 * 3600 * 24) {
        return Math.ceil(secondDifference / (356 * 3600 * 24)).toString() + ' year ago'
      } else if (secondDifference >= 30 * 3600 * 24) {
        return Math.ceil(secondDifference / (30 * 3600 * 24)) + ' month ago'
      } else if (secondDifference >= 7 * 3600 * 24) {
        return Math.ceil(secondDifference / (7 * 3600 * 24)) + ' week ago'
      } else if (secondDifference >= 1 * 3600 * 24) {
        return Math.ceil(secondDifference / (1 * 3600 * 24)) + ' days ago'
      } else {
        if (secondDifference > 3600) {
          return Math.ceil(secondDifference / 3600) + ' hours ago'
        } else if (secondDifference > 60) {
          return Math.ceil(secondDifference / 60) + ' minutes ago'
        } else {
          return secondDifference + ' seconds ago'
        }
      }
    } catch (e) {
      return ''
    }
  }

  function fetchAllWall() {
    // getWallList('tkc', user.branchId, user.userId, startIndex, timeOffset, limit)
    //   .then((response) => {
    //     let resultObject = response.data.responseObject
    //     let resultCode = response.data.responseCode
    //     if (resultCode == 0) {
    //       setState({
    //         ...state,
    //         loading: false,
    //         Wall: response.data.responseObject,
    //       })
    //     }
    //   })
    //   .catch((error) => {
    //     setState({
    //       ...state,
    //       loading: false,
    //       errorMessage: error.message,
    //     })
    //   })
  }

  function createwallinput(e: any) {
    setState({...state,commentwall : e.target.value})
  }
  function youtube_parser(url: string) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    var match = url.match(regExp)
    return match && match[7].length == 11 ? match[7] : false
  }
  function processCommentText() {
    let imgFiles = ['.jpg', '.png', '.bmp', '.gif', '.svg', '.JPG', '.PNG', '.BMP', '.GIF', '.SVG']
    let videoFiles = ['.mp4', '.webm', '.waw', '.mp3', '.MP4', '.WEBM']
    let docFiles = ['.pdf', '.PDF']
    let comments: any = {}
    let content = state.commentwall
    let splitWords = content.split(' ')
    splitWords.map((word) => {
      if (word.indexOf('youtube.com/') > -1 && word.indexOf('https://') > -1) {
        comments.youtube = 'https://www.youtube.com/embed/' + youtube_parser(word)
        content = content.replace(word, '')
      } else {
        if (word.indexOf('https://') > -1) {
          let set = false
          imgFiles.map((imgformats) => {
            if (word.indexOf(imgformats) > -1) {
              comments.imagemedias = word
              set = true
            }
          })
          videoFiles.map((videoformats) => {
            if (word.indexOf(videoformats) > -1) {
              comments.videomedias = word
              set = true
            }
          })
          docFiles.map((docformats) => {
            if (word.indexOf(docformats) > -1) {
              comments.pdfmedias = word
              set = true
            }
          })
          if (!set) {
            comments.otherlink = word
          }
          content = content.replace(word, '')
        }
      }
    })
    if (lastUploadedFile) {
      let set = false
      imgFiles.map((imgformats) => {
        if (lastUploadedFile.indexOf(imgformats) > -1) {
          comments.imagemedias = lastUploadedFile
          set = true
        }
      })
      videoFiles.map((videoformats) => {
        if (lastUploadedFile.indexOf(videoformats) > -1) {
          comments.videomedias = lastUploadedFile
          set = true
        }
      })

      docFiles.map((docformats) => {
        if (lastUploadedFile.indexOf(docformats) > -1) {
          comments.pdfmedias = lastUploadedFile
          set = true
        }
      })
      if (!set) {
        comments.otherlink = lastUploadedFile
      }
    }
    comments.comment = content

    return comments
  }

  const HandleUploadfile = (e: any) => {
    const file = e.target.files[0]
    getBase64(file).then((data) => {
      uploadfile(file.name, data).then((resp) => {
        lastUploadedFile = process.env.REACT_APP_API_URL + '/uploads/' + resp.data.name
      })
    })
  }

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }
  function Creatpostapi() {
    // NewPost(
    //   'tkc',
    //   user.branchId,
    //   user.userId,
    //   user.username,
    //   CurrentVisibility,
    //   currentExpiry,
    //   processCommentText(),
    //   user.pic
    // )
    //   .then((response) => {
    //     state.commentwall=""
        
    //     fetchAllWall()
    //   })
    //   .catch((error) => {
    //   })
  }

  function like_And_Revert(selwallid: string, isliked: boolean) {
    // LIKE_API('tkc', user.userId, selwallid, entityType, user.username, isliked).then((resp) => {
    //   fetchAllWall()
    // })
  }

  let {loading, Wall, errorMessage} = state

  return (
    <React.Fragment>
      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div id='kt_toolbar_container' className='container-fluid d-flex flex-stack'>
          <div
            id='kt_page_title'
            data-kt-swapper='true'
            data-kt-swapper-mode='prepend'
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className='page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0'
          >
            <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>Wall</h1>
            <span className='h-20px border-gray-200 border-start mx-4'></span>
          </div>
          <a
            href='#'
            className='btn btn-sm btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
            id='kt_toolbar_primary_button'
          >
            Create
          </a>
        </div>
      </div>

      <div className='row g-5 mx-xl-5'>
        {Wall.length > 0 &&
          Wall.map((data) => {
            let pcont: string = data.postcontent
            lstPostcontent = JSON.parse(pcont)
            return (
              <div className='card-body my-3 bg-white'>
                <div className='card-body pb-3' key={data.wallentryid}>
                  <div className='d-flex align-items-center mb-5'>
                    <div className='d-flex align-items-center flex-grow-1'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={data.userprofile} alt='' />
                      </div>

                      <div className='d-flex flex-column'>
                        <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                          {data.username}
                        </a>

                        <span className='text-gray-400 fw-bold'>{getAgo(data.createdate)}</span>
                      </div>
                    </div>
                  </div>

                  <div className='mb-5'>
                    <div className='text-gray-800 mb-5'>{lstPostcontent.comment}</div>

                    <img
                      className={
                        lstPostcontent.imagemedias
                          ? 'bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5  showleftmenu'
                          : 'bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5  hideleftmenu'
                      }
                      width='100%'
                      alt=''
                      src={lstPostcontent.imagemedias}
                    />

                    <video
                      className={
                        lstPostcontent.videomedias
                          ? 'embed-responsive-item rounded mb-5  showleftmenu'
                          : 'embed-responsive-item rounded mb-5 hideleftmenu'
                      }
                      src={lstPostcontent.videomedias}
                      width='100%'
                      controls
                    ></video>

                    <div
                      className={
                        lstPostcontent.pdfmedias ? 'mb-5 showleftmenu' : 'mb-5 hideleftmenu'
                      }
                    >
                      <iframe
                        title='widget11-video'
                        className='embed-responsive-item rounded h-500px w-100'
                        src={lstPostcontent.pdfmedias}
                        allowFullScreen={true}
                      />
                    </div>

                    <div className='d-flex align-items-center mb-5'>
                      <Link to={`/wallcomment/${data.wallentryid}`}>
                        <span className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com012.svg'
                            className='svg-icon-2'
                          />
                          {data.postcomments}
                        </span>
                      </Link>

                      <span
                        className='btn btn-sm bg-btn-light bg-btn-color-muted bg-btn-active-light-danger px-4 py-1 me-4'
                        onClick={() => like_And_Revert(data.wallentryid, data.isLiked)}
                      >
                        {data.isLiked ? (
                          <KTSVG
                            path='/media/icons/duotune/general/gen030.svg'
                            className='svg-icon-2 svg-icon-danger'
                          />
                        ) : (
                          <KTSVG
                            path='/media/icons/duotune/general/gen030.svg'
                            className='svg-icon-2'
                          />
                        )}

                        {data.totalLikes}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content' style={{width: '55vw'}}>
            <div className='modal-header'>
              <h5 className='modal-title'>Post something</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body d-flex'>
              <div className='symbol symbol-45px me-5'>
                {/* <img src={user.pic} alt='' /> */}
              </div>
              <textarea
                className='form-control form-control-flush'
                onChange={createwallinput}
                value={state.commentwall}
                placeholder="What's on your mind?"
              ></textarea>
            </div>
            <div className='modal-footer d-flex row'>
              <div className='col-6 d-flex'>
                <div className='my-0'>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    // data-kt-menu-trigger='hover'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG
                      path='/media/icons/duotune/coding/cod001.svg'
                      className='svg-icon svg-icon-3x me-10'
                    />
                  </button>

                  <div
                    className='menu menu-sub menu-sub-dropdown w-250px w-md-250px Wallvisibility'
                    data-kt-menu='true'
                  >
                    <div>
                      <div className='everyone m-2 row' id='everyone'>
                        <span className='col-2'>
                          <KTSVG
                            path='/media/icons/duotune/files/fil002.svg'
                            className='svg-icon svg-icon-2hx svg-icon-primary text-hover-primary'
                          />
                        </span>
                        <span className='col-8 text-center fs-4 py-1 text-primary text-hover-primary'>
                          Everyone
                        </span>
                        <span className='col-2'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen026.svg'
                            className='svg-icon svg-icon-2hx svg-icon-primary'
                          />
                        </span>
                      </div>
                      <div className='classmates m-2 row' id='classmates'>
                        <span className='col-2'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen047.svg'
                            className='svg-icon svg-icon-2hx text-hover-primary'
                          />
                        </span>
                        <span className='col-8 text-center fs-4 py-1 text-hover-primary'>
                          Classmates
                        </span>
                        <span className='col-2'></span>
                      </div>
                      <div className='yeargroups m-2 row' id='yeargroups'>
                        <span className='col-2'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon svg-icon-2hx'
                          />
                        </span>
                        <span className='col-8 text-center fs-4 py-1 text-hover-primary'>
                          Yeargroups
                        </span>
                        <span className='col-2'></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-toggle='popover' data-placement='top' title='Visibility'></div>
                <div
                  className='dropdown dropdown-inline mr-4'
                  data-toggle='popover'
                  data-placement='top'
                  title='Expiry'
                >
                  <button
                    type='button'
                    className='btn btn-light-primary btn-icon btn-sm'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <KTSVG
                      path='/media/icons/duotune/files/fil002.svg'
                      className='svg-icon-muted svg-icon-2hx'
                    />
                  </button>
                  <div className='dropdown-menu'>...</div>
                </div>
              </div>
              <div className='col-5 d-flex justify-content-end'>
                <div className='bg-light px-2 mx-3'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen006.svg'
                    className='svg-icon svg-icon-3x svg-icon-primary'
                  />
                  Photo/Video
                  <input
                    type='file'
                    accept='.jpef, .png, .jpg'
                    onChange={HandleUploadfile}
                    style={{
                      position: 'absolute',
                      opacity: '0',
                      cursor: 'pointer',
                      padding: '1.5em',
                      display: 'inline-block',
                      background: '#a3a4a5',
                      right: '9vw',
                      width: '100px',
                      height: '38px',
                      textAlign: 'center',
                      lineHeight: '26px',
                      borderRadius: '100px',
                      fontSize: '12px',
                      color: '#0a081e',
                      textTransform: 'uppercase',
                      transition: '0.2s ease-in-out',
                    }}
                  />
                </div>
                <button type='button' className='btn btn-primary' onClick={Creatpostapi}>
                  Post
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Wall
