import {parse} from 'path'
import React, {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, useLocation, useParams} from 'react-router-dom'
import {RootState} from '../../../setup'
import {KTSVG, toAbsoluteUrl} from '../../../_ecadme/helpers'
import {UserModel} from '../../modules/auth/models/UserModel'
import {
  getpost_reply,
  LIKE_API,
  COMMENT_LIKE_API,
  ADD_COMMENT_API,
  ADD_COMMENT_REPLY_API,
} from '../../modules/wall/redux/WallCRUD'
import {IWallState} from '../../modules/wall/redux/WallRedux'
import {
  IWallCommentModel,
  IWallModel,
  ICommentReplyModel,
  IWallCommentListModel,
  Ipostcontent,
} from '../../modules/wall/WallModel'

interface IState {
  loading: boolean
  WallComment: IWallCommentListModel[]
  postContent: IWallModel
  errorMessage: string
  strpostcontents: string
  pscontents: Ipostcontent
  widd: object
  CurrentComment: string
  CurrentCommentReply: string
  CurrentReplyTextbox: string
}

interface IProps {}

const Wallcomment: FC<IProps> = () => {
  let [state, setState] = useState<IState>({
    loading: false,
    WallComment: [] as IWallCommentListModel[],
    postContent: {} as IWallModel,
    pscontents: {} as Ipostcontent,
    errorMessage: '',
    strpostcontents: '',
    widd: [],
    CurrentComment: '',
    CurrentCommentReply: '',
    CurrentReplyTextbox: '',
  })

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const {wid} = useParams<{wid: string}>()

  let timeOffset: number = new Date().getTimezoneOffset()
  let lstPostcontent: Ipostcontent
  let entityType = 12

  function getAgo(dt: string) {
    try {
      let dateVal: Date = new Date(dt.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'))
      let postTime: number = dateVal.getTime()
      let curentDate: Date = new Date()
      let CurrentTime: number = curentDate.getTime()
      let secondDifference: number = (CurrentTime - postTime) / 1000
      if (secondDifference >= 365 * 3600 * 24) {
        return Math.ceil(secondDifference / (356 * 3600 * 24)).toString() + ' year ago'
      } else if (secondDifference >= 30 * 3600 * 24) {
        return Math.ceil(secondDifference / (30 * 3600 * 24)) + ' month ago'
      } else if (secondDifference >= 7 * 3600 * 24) {
        return Math.ceil(secondDifference / (7 * 3600 * 24)) + ' week ago'
      } else if (secondDifference >= 1 * 3600 * 24) {
        return Math.ceil(secondDifference / (1 * 3600 * 24)) + ' days ago'
      } else {
        if (secondDifference > 3600) {
          return Math.ceil(secondDifference / 3600) + ' hours ago'
        } else if (secondDifference > 60) {
          return Math.ceil(secondDifference / 60) + ' minutes ago'
        } else {
          return secondDifference + ' seconds ago'
        }
      }
    } catch (e) {
      return ''
    }
  }

  useEffect(() => {
    setState({...state, loading: true})
    setTimeout(() => {
      walldatas()
    }, 100)
  }, [])

  function walldatas() {
    // getpost_reply('tkc', user.userId, wid, timeOffset)
    //   .then((response) => {
    //     let resultCode = response.data.responseMessage
    //     if (resultCode === 'SUCCESS') {
    //       let resultObject = response.data.responseObject
    //       setState({
    //         ...state,
    //         loading: false,
    //         WallComment: response.data.responseObject.commentList,
    //         postContent: response.data.responseObject.PostDetails,
    //         strpostcontents: response.data.responseObject.PostDetails.postcontent,
    //       })
    //     }
    //   })
    //   .catch((error) => {
    //     setState({
    //       ...state,
    //       loading: false,
    //       errorMessage: error,
    //     })
    //   })
  }

  function WALL_like_And_Revert_LIKe(selwallid: string, isliked: boolean) {
    // LIKE_API('tkc', user.userId, selwallid, entityType, user.username, isliked).then((resp) => {
    //   walldatas()
    // })
  }

  function COMMENT_like_And_Revert(commentid: number, isliked: boolean) {
    // COMMENT_LIKE_API('tkc', user.userId, commentid, entityType, user.username, isliked).then(
    //   (resp) => {
    //     walldatas()
    //   }
    // )
  }

  function showOrHideReplies(commentId: number, flag: number) {
    if (flag === 1) {
      let dvelm: any = document.getElementById('dv_' + commentId.toString())
      dvelm.style.display = 'none'
      let dvelmview: any = document.getElementById('h4viewrepl_' + commentId.toString())
      dvelmview.style.display = 'block'
      let dvelmhide: any = document.getElementById('h4hiderepl_' + commentId.toString())
      dvelmhide.style.display = 'none'
    } else {
      let dvelm: any = document.getElementById('dv_' + commentId.toString())
      dvelm.style.display = 'block'
      let dvelmview: any = document.getElementById('h4viewrepl_' + commentId.toString())
      dvelmview.style.display = 'none'
      let dvelmhide: any = document.getElementById('h4hiderepl_' + commentId.toString())
      dvelmhide.style.display = 'block'
    }
  }

  function CommentInput(e: any) {
    setState({...state, CurrentComment: e.target.value})
  }

  function Comment_REPLY_Input(e: any) {
    setState({...state, CurrentCommentReply: e.target.value, CurrentReplyTextbox: e.target.id})
  }

  function addComment(wallid: string) {
    let totalDisLikes: number = 0
    let totalLikes: number = 0
    let isLiked: boolean = false
    let isDisLiked: boolean = false
    if (state.CurrentComment === '') {
      return
    }

    // ADD_COMMENT_API(
    //   user.userId,
    //   wallid,
    //   6,
    //   state.CurrentComment,
    //   user.username,
    //   'tkc',
    //   user.pic
    // ).then((response) => {
    //   let respsp: any = JSON.stringify(response)
    //   let parsedResponse: any = JSON.parse(respsp)

    //   if (parsedResponse.data.responseMessage === 'SUCCESS') {
    //     if (parsedResponse.data.responseObject.commentId === '0') {
    //       alert('Unable to save your comment, Please try again')
    //     } else {
    //       state.CurrentComment = ''
    //       walldatas()
    //     }
    //   } else {
    //   }
    // })
  }

  function showorhideReplyInput(commentId: number, flag: number) {
    if (flag === 0) {
      let dvelm: any = document.getElementById('dvreply_' + commentId.toString())
      dvelm.style.display = 'block'
      let dvelmview: any = document.getElementById('dvreply_cancle_' + commentId.toString())
      dvelmview.style.display = 'block'
      let dvelmhide: any = document.getElementById('dvreply_reply_' + commentId.toString())
      dvelmhide.style.display = 'none'
    } else {
      let dvelm: any = document.getElementById('dvreply_' + commentId.toString())
      dvelm.style.display = 'none'
      let dvelmview: any = document.getElementById('dvreply_cancle_' + commentId.toString())
      dvelmview.style.display = 'none'
      let dvelmhide: any = document.getElementById('dvreply_reply_' + commentId.toString())
      dvelmhide.style.display = 'block'
    }
  }

  function addReply(commentId: number) {
    let totalDisLikes: number = 0
    let totalLikes: number = 0
    let isLiked: boolean = false
    let isDisLiked: boolean = false
    if (state.CurrentCommentReply === '') {
      return
    }

    // ADD_COMMENT_REPLY_API(
    //   user.userId,
    //   postContent.wallentryid,
    //   commentId,
    //   14,
    //   state.CurrentCommentReply,
    //   user.username,
    //   'tkc',
    //   user.pic
    // ).then((response) => {
    //   state.CurrentCommentReply = ''
    //   let dvelm: any = document.getElementById('dvreply_' + commentId.toString())
    //   dvelm.style.display = 'none'
    //   let dvelmview: any = document.getElementById('dvreply_cancle_' + commentId.toString())
    //   dvelmview.style.display = 'none'
    //   let dvelmhide: any = document.getElementById('dvreply_reply_' + commentId.toString())
    //   dvelmhide.style.display = 'block'
    //   walldatas()
    // })
  }

  let {
    loading,
    WallComment,
    postContent,
    strpostcontents,
    errorMessage,
    pscontents,
    CurrentComment,
    CurrentCommentReply,
    CurrentReplyTextbox,
  } = state

  return (
    <React.Fragment>
      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}
        <div id='kt_toolbar_container' className='container-fluid d-flex flex-stack'>
          <div
            id='kt_page_title'
            data-kt-swapper='true'
            data-kt-swapper-mode='prepend'
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className='page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0'
          >
            <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
              Wall Comment
            </h1>
            <span className='h-20px border-gray-200 border-start mx-4'></span>
          </div>
        </div>
      </div>
      <Link to={'/wall'} className='btn btn-primary'>
        <KTSVG
          path='/media/icons/duotune/arrows/arr002.svg'
          className='svg-icon svg-icon-light svg-icon-2'
        />
        Back
      </Link>
      <div className='card-body my-3 bg-white g-5 mx-xl-5'>
        <div className='card-body pb-3'>
          <div className='d-flex align-items-center mb-5'>
            <div className='d-flex align-items-center flex-grow-1'>
              <div className='symbol symbol-45px me-5'>
                <img src={postContent.userprofile} alt='' />
              </div>

              <div className='d-flex flex-column'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder'>
                  {postContent.username}
                </a>

                <span className='text-gray-400 fw-bold'>{getAgo(postContent.createdate)}</span>
              </div>
            </div>
          </div>

          <div className='mb-5'>
            <div className='text-gray-800 mb-5'>
              comments
              {pscontents.comment}
            </div>

            <div className='d-flex align-items-center mb-5'>
              <span className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-1 me-4'>
                <KTSVG
                  path='/media/icons/duotune/communication/com012.svg'
                  className='svg-icon-3'
                />
                {postContent.postcomments}
              </span>
              <span
                className='btn btn-sm bg-btn-light bg-btn-color-muted bg-btn-active-light-danger px-4 py-1 me-4'
                onClick={() =>
                  WALL_like_And_Revert_LIKe(postContent.wallentryid, postContent.isLiked)
                }
              >
                {postContent.isLiked ? (
                  <KTSVG
                    path='/media/icons/duotune/general/gen030.svg'
                    className='svg-icon-2 svg-icon-danger'
                  />
                ) : (
                  <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
                )}
                {postContent.totalLikes}
              </span>
            </div>

            <div className='position-relative mb-6'>
              <textarea
                className='form-control border-1 border-dark p-4 pe-10 resize-none min-h-25px'
                data-kt-autosize='true'
                rows={1}
                placeholder='Add a public comment...'
                onChange={(e) => CommentInput(e)}
                value={CurrentComment}
              ></textarea>
              <div
                className='position-absolute top-0 end-0 py-1 me-1'
                onClick={() => addComment(postContent.wallentryid)}
              >
                <span className='btn btn-icon-dark btn-sm btn-active-color-primary ps-0'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen016.svg'
                    className='svg-icon-2x mb-3'
                  />
                </span>
              </div>
            </div>

            {WallComment.length > 0 &&
              WallComment.map((wallcomnt, index) => {
                return (
                  <div key={index}>
                    <div className='mb-7 mx-5'>
                      <div className='d-flex mb-5'>
                        <div className='symbol symbol-45px me-5'>
                          <img src={wallcomnt.userProfilePic} alt='' />
                        </div>
                        <div className='d-flex flex-column flex-row-fluid'>
                          <div className='d-flex align-items-center flex-wrap mb-1'>
                            <a href='#' className='text-gray-800 text-hover-primary fw-bolder me-2'>
                              {wallcomnt.userName}
                            </a>
                            <span className='text-gray-400 fw-bold fs-7'>
                              {getAgo(wallcomnt.commentedDate)}
                            </span>
                          </div>
                          <span className='text-gray-800 fs-7 fw-normal pt-1'>
                            {wallcomnt.comment}
                          </span>
                        </div>
                      </div>

                      <div className='d-flex align-items-center mb-5 px-5 mx-5'>
                        <span className='btn btn-sm bg-btn-light bg-btn-color-muted bg-btn-active-light-danger px-4 py-1'>
                          <KTSVG
                            path='/media/icons/duotune/communication/com012.svg'
                            className='svg-icon-3'
                          />
                          {wallcomnt.replies.length}
                        </span>

                        <span
                          className='btn btn-sm bg-btn-light bg-btn-color-muted bg-btn-active-light-danger px-4 py-1 me-4'
                          onClick={() =>
                            COMMENT_like_And_Revert(wallcomnt.commentId, wallcomnt.isLiked)
                          }
                        >
                          {wallcomnt.isLiked ? (
                            <KTSVG
                              path='/media/icons/duotune/general/gen030.svg'
                              className='svg-icon-2 svg-icon-danger'
                            />
                          ) : (
                            <KTSVG
                              path='/media/icons/duotune/general/gen030.svg'
                              className='svg-icon-2'
                            />
                          )}
                          {wallcomnt.totalLikes}
                        </span>
                        <span
                          className='btn btn-sm btn-light btn-color-muted btn-active-light-dark px-4 py-1'
                          onClick={() => showorhideReplyInput(wallcomnt.commentId, 0)}
                          id={`dvreply_reply_${wallcomnt.commentId}`}
                        >
                          Reply
                        </span>
                        <span
                          className='btn btn-sm btn-light btn-color-muted btn-active-light-dark px-4 py-1 hideleftmenu'
                          onClick={() => showorhideReplyInput(wallcomnt.commentId, 1)}
                          id={`dvreply_cancle_${wallcomnt.commentId}`}
                        >
                          Cancle
                        </span>
                      </div>

                      <div
                        className='position-relative px-5 mx-5 mb-6 hideleftmenu'
                        id={`dvreply_${wallcomnt.commentId}`}
                      >
                        <textarea
                          className='form-control border-0.5 border-dark p-3 pe-10 resize-none min-h-25px'
                          data-kt-autosize='true'
                          rows={1}
                          placeholder='Add a public comment...'
                          onChange={(e) => Comment_REPLY_Input(e)}
                          value={
                            `txtcommentreply_${wallcomnt.commentId}` === CurrentReplyTextbox
                              ? CurrentCommentReply
                              : ''
                          }
                          id={`txtcommentreply_${wallcomnt.commentId}`}
                        ></textarea>
                        <div
                          className='position-absolute top-0 end-0 pb-5 me-3'
                          onClick={() => addReply(wallcomnt.commentId)}
                        >
                          <span className='btn btn-icon-dark btn-sm btn-active-color-primary ps-0'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen016.svg'
                              className='svg-icon-2x mb-3'
                            />
                          </span>
                        </div>
                      </div>

                      <div
                        className={
                          wallcomnt.replies.length > 0
                            ? 'mx-xl-5 px-5 btn btn-sm btn-light btn-color-muted btn-active-light-success py-1 me-4 showleftmenu'
                            : 'mx-xl-5 px-5 btn btn-sm btn-light btn-color-muted btn-active-light-success py-1 me-4 hideleftmenu'
                        }
                        style={{cursor: 'pointer'}}
                      >
                        <h4
                          className='mx-xl-5 py-3'
                          onClick={() => showOrHideReplies(wallcomnt.commentId, 0)}
                          id={`h4viewrepl_${wallcomnt.commentId}`}
                        >
                          View all {wallcomnt.replies.length} Replies
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr004.svg'
                            className='svg-icon svg-icon-2 svg-icon-info mx-3'
                          />
                        </h4>

                        <h4
                          className='mx-xl-5 py-3 hideleftmenu'
                          onClick={() => showOrHideReplies(wallcomnt.commentId, 1)}
                          id={`h4hiderepl_${wallcomnt.commentId}`}
                        >
                          Hide Replies
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr003.svg'
                            className='svg-icon svg-icon-2 svg-icon-info mx-3'
                          />
                        </h4>
                      </div>

                      <div className='hideleftmenu' id={`dv_${wallcomnt.commentId}`}>
                        {wallcomnt.replies.map((replies, index) => {
                          return (
                            <div className='mx-5' id='dvcommentReply' key={index}>
                              <div className='mb-7 mx-xl-5 px-5'>
                                <div className='d-flex mb-5'>
                                  <div className='symbol symbol-30px me-5'>
                                    <img src={replies.userProfilePic} alt='' />
                                  </div>
                                  <div className='d-flex flex-column flex-row-fluid'>
                                    <div className='d-flex align-items-center flex-wrap mb-1'>
                                      <div className='text-gray-800 text-hover-primary fw-bolder me-2'>
                                        {replies.userName}
                                      </div>
                                      <span className='text-gray-400 fw-bold fs-7'>
                                        {getAgo(replies.commentedDate)}
                                      </span>
                                    </div>
                                    <span className='text-gray-800 fs-7 fw-normal pt-1'>
                                      {replies.comment}
                                    </span>
                                  </div>
                                </div>

                                <div className='d-flex align-items-center mb-5 px-5 mx-5'>
                                  <span className='btn btn-sm bg-btn-light bg-btn-color-muted bg-btn-active-light-danger px-4 py-1 me-4'>
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen030.svg'
                                      className='svg-icon-2'
                                    />
                                    {replies.totalLikes}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Wallcomment
