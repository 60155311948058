import React, { FC } from 'react';
import { getPaginationItems } from './Pagination1';
import PageLink from './PageLink';
import './Pagination.css';

// totalPosts={allData.taskData.length}
//           postPerPage={postsPerPage}
//           setCurrentPagess={setCurrentPages}
//           currentPage={currentPage}
// --new---
// export type Props = {
//   currentPage: number;
//   lastPage: number;
//   maxLength: number;
//   setCurrentPage: (page: number) => void;
// };
export type Props = {
  totalPosts: number;
  postPerPage: number;
  setCurrentPages: (page: number) => void;
  currentPage: number;
};

interface IProps {
  totalPosts: number;
  postPerPage: number;
  setCurrentPages: (page: number) => void;
  currentPage: number;
}


const Pagination: FC<IProps> = ({
  totalPosts,
  postPerPage,
  setCurrentPages,
  currentPage,
}: Props) => {
  const pageNums = getPaginationItems(currentPage, postPerPage, totalPosts);

  return (
    <nav className="pagination" aria-label="Pagination">
      <PageLink
        disabled={currentPage === 1}
        onClick={() => setCurrentPages(currentPage - 1)}
      >
        Previous
      </PageLink>
      {pageNums.map((pageNum: any, idx: any) => (
        
        <PageLink
          key={idx}
          active={currentPage === pageNum}
          disabled={isNaN(pageNum)}
          onClick={() => setCurrentPages(pageNum)}
        >
          {!isNaN(pageNum) ? pageNum : '...'}
        </PageLink>
      ))}
      <PageLink
        disabled={currentPage === postPerPage}
        onClick={() => setCurrentPages(currentPage + 1)}
      >
        Next
      </PageLink>
    </nav>
  );
}

export default Pagination