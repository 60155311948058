import React, {useEffect, useState} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import {IMyAddressModel} from '../../../models/employee_model/MyAddressModel'
import { UserModel } from '../../../modules/auth/models/UserModel'
import {employeeAddress} from '../../../modules/employee/empcrud/EmployeeCRUD'

interface MyAddress {
  loading: boolean
  myAddressData: IMyAddressModel
}

const MyAddress = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [state, setState] = useState<MyAddress>({
    loading: false,
    myAddressData: {} as IMyAddressModel,
  })

  useEffect(() => {
    setState({ ...state, loading: true })
    setTimeout(() => {
    getMyAddressData()
  }, 100)
  }, [])

  function getMyAddressData() {
    employeeAddress(user.employeeID)
      .then((response) => {
        const myAddData = response.data
        console.log(myAddData)
        setState({...state, myAddressData: myAddData, loading: false})
      })
      .catch((error) => {
        alert(error)
        setState({...state, myAddressData: {} as IMyAddressModel, loading: false})
      })
  }

  const {myAddressData} = state

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      {/* <div className='card-header cursor-pointer'>
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0'>Profile Details</h3>
      </div>

      <Link to='/crafted/account/settings' className='btn btn-primary align-self-center'>
        Edit Profile
      </Link>
    </div> */}

      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Current Address1</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myAddressData.curntAddress1 == '' ? '-' : myAddressData.curntAddress1}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Current Address2</label>

          <div className='col-lg-9 fv-row'>
            <span className='fw-bolder fs-6'>{myAddressData.curntAddress2 == '' ? '-' : myAddressData.curntAddress2}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>
            Current Country
          </label>

          <div className='col-lg-9 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>{myAddressData.curntCountryName == '' ? '-' : myAddressData.curntCountryName}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Current State</label>

          <div className='col-lg-9'>
            <a href='#' className='fw-bolder fs-6 text-dark text-hover-primary'>
              {myAddressData.curntStateName == '' ? '-' : myAddressData.curntStateName}
            </a>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>
            Current City
          </label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myAddressData.curntCityName == '' ? '-' : myAddressData.curntCityName}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Current PinCode</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myAddressData.curntPincode == 0 ? '-' : myAddressData.curntPincode}</span>
          </div>
        </div>

        <div className='row mb-10'>
          <label className='col-lg-3 fw-bold text-muted'>Permanent Address1</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6'>{myAddressData.perAddress1 == '' ? '-' : myAddressData.perAddress1}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-3 fw-bold text-muted'>Permanent Address2</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6'>{myAddressData.perAddress2 == '' ? '-' : myAddressData.perAddress2}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-3 fw-bold text-muted'>Permanent Coutnry</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6'>{myAddressData.perCountryName == '' ? '-' : myAddressData.perCountryName}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-3 fw-bold text-muted'>Permanent State</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6'>{myAddressData.perStateName == '' ? '-' : myAddressData.perStateName}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-3 fw-bold text-muted'>Permanent City</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6'>{myAddressData.perCityName == '' ? '-' : myAddressData.perCityName}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-3 fw-bold text-muted'>Permanent PinCode</label>

          <div className='col-lg-9'>
            <span className='fw-bolder fs-6'>{myAddressData.perPincode == 0 ? '-' : myAddressData.perPincode}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyAddress
