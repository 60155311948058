import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../../_ecadme/helpers'
import { EmpEducationModel } from '../../../models/employee_model/EmployeeEducationModel'
import {
  deleteEmpEducation,
  getAllEmpEducation,
  getAllEmpEducationByEmpID,
  isActiveEmpEducation,
} from '../../../modules/employee/empcrud/EmployeeEducationCRUD'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { UserModel } from '../../../modules/auth/models/UserModel'
import { Button, Modal } from 'react-bootstrap-v5'

type Props = {
}

interface IEmpEducation {
  loading: boolean
  empEducationData: EmpEducationModel[]
  empEducation: EmpEducationModel
  newEmpID: number
}

const MyEducationDetails: React.FC<Props> = () => {
  const location = useLocation()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const [state, setState] = useState<IEmpEducation>({
    loading: false,
    empEducationData: [] as EmpEducationModel[],
    empEducation: {} as EmpEducationModel,
    newEmpID: 0,
  })

  const [show, setShow] = useState(false)

  function handleClose() {
    setShow(false)
  }

  function handleShow(data: EmpEducationModel) {
    setState({ ...state, empEducation: data })
    setShow(true)
  }

  useEffect(() => {
    setState({ ...state, loading: true })
    setTimeout(() => {
      getEmpEducationDataByEmpID()
    }, 100)
  }, [])

  function getEmpEducationDataByEmpID() {
    getAllEmpEducationByEmpID(user.employeeID)
      .then((response) => {
        let responseData = response.data.responseObject
        setState({ ...state, empEducationData: responseData, loading: false })
      })
      .catch((error) => {
        toast.error(`${error}`, {position: 'top-center'})
        setState({ ...state, empEducationData: [], loading: false })
      })
  }

  function deleteDeparmentItem(empEduId: any) {
    const result = window.confirm('Are You Sure To Delete')
    if (result) {
      deleteEmpEducation(empEduId)
        .then((response) => {
          toast.success('Deleted Successfully', { position: 'top-center' })
          getEmpEducationDataByEmpID()
        })
        .catch((error) => {
          toast.error(`${error}`, {position: 'top-center'})
        })
    } else {
      return
    }
  }

  function checkedFunction(event: any) {
    const temId = event.target.id
    const temIsAct = event.target.checked
    const result = window.confirm('Are You Sure To Change??')
    if (result) {
      isActiveEmpEducation(temId, temIsAct)
        .then((response) => {
          getEmpEducationDataByEmpID()
        })
        .catch((error) => {
          toast.error(`${error}`, {position: 'top-center'})
        })
    } else {
      return
    }
  }

  return (
    <React.Fragment>
      <div className={`card `}>
        {/* begin::Header */}
        {/* <div className='card-header border-0 pt-5'> */}
          {/* <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Employee Education Details</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Over 500 Employee</span>
          </h3> */}
          {/* <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <Link to='/employees/create/education' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Add New
          </Link>
        </div> */}
        {/* </div> */}
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>Institute Name</th>
                  <th className='min-w-140px'>Department Name</th>
                  <th className='min-w-140px'>Category Name</th>
                  <th className='min-w-140px'>Passing Year&nbsp;(%)</th>
                  <th className='min-w-140px'>isActive</th>
                  <th className={location.pathname === '/employee/details/education' || location.pathname === '/profile/myeducation' ? 'd-none' : 'min-w-100px text-end'}>View / Edit / Delete</th>
                  <th className={location.pathname === '/employee/details/education' || location.pathname === '/profile/myeducation' ? 'min-w-25px text-end pe-5' : 'd-none'}>View</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {state.empEducationData.length > 0 &&
                  state.empEducationData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {data.instituteName}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {data.eduDepartmentName}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {data.eduCategoryName}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {data.passingYear}&nbsp;({data.percentage}%)
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={`${data.employeeEducationID}`}
                              checked={data.isActive}
                              onChange={(e) => checkedFunction(e)}
                            />
                          </div>
                        </td>
                        <td className={location.pathname === '/employee/details/education' || location.pathname === '/profile/myeducation' ? '' : 'd-none'}>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon btn-bg-light bg-hover-success text-hover-inverse-success btn-sm me-1 text-success text-hover-light'
                              onClick={() => handleShow(data)}
                            >
                              <span className='fa fa-eye lg'></span>
                              {/* <KTSVG
                                path='/media/icons/duotune/art/art008.svg'
                                className='svg-icon-3'
                              /> */}
                            </span>
                          </div>
                        </td>
                        <td className={location.pathname === '/employee/details/education' || location.pathname === '/profile/myeducation' ? 'd-none' : ''}>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon btn-bg-light bg-hover-success text-hover-inverse-success btn-sm me-1 text-success text-hover-light'
                              onClick={() => handleShow(data)}
                            >
                              <span className='fa fa-eye lg'></span>
                              {/* <KTSVG
                                path='/media/icons/duotune/art/art008.svg'
                                className='svg-icon-3'
                              /> */}
                            </span>
                            <Link
                              to={`/employees/edit/education/${data.employeeEducationID}`}
                              className='btn btn-icon btn-bg-light bg-hover-primary text-hover-inverse-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3 svg-icon-primary'
                              />
                            </Link>
                            <div
                              onClick={() => deleteDeparmentItem(data.employeeEducationID)}
                              className='btn btn-icon btn-bg-light bg-hover-danger text-hover-inverse-danger btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3 svg-icon-danger'
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal size='lg' scrollable={true} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Education Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body pt-2'>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Education Category Name :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>{state.empEducation.eduCategoryName}</span>
            </div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Institute Name :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>{state.empEducation.instituteName}</span>
            </div>
            <div className="w-100 border-bottom d-md-block my-1"></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Education Department Name :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>{state.empEducation.eduDepartmentName}</span>
            </div>
            <div className="w-100 border-bottom d-md-block my-1"></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Passing Year :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>{state.empEducation.passingYear}</span>
            </div>
            <div className="w-100 border-bottom d-md-block my-1"></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Percentage :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>{state.empEducation.percentage}</span>
            </div>
            <div className="w-100 border-bottom d-md-block my-1"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant='primary' onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default MyEducationDetails
