import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const GET_Wall_LIST = `${API_URL}/api/sa-rest/v1/wall/allPostDetails`

export const Upload_File = `${API_URL}/upload`
export const Upload_File64 = `${API_URL}/api/sa-rest/upload-base64`

export const Create_Post = `${API_URL}/api/sa-rest/v1/wall/createPost`

export const Show_LIKED_USer = `${API_URL}/api/sa-rest/v1/getLikesForAsset`

export const GET_POST_REPLY_DETail = `${API_URL}/api/sa-rest/v1/wall/getPostDetail`

export const ADD_POST_COMMENT = `${API_URL}/api/sa-rest/v1/addCommentToAssets`

export const ADD_POST_COMMENT_REPLY = `${API_URL}/api/sa-rest/v1/addRepliesToComment`

export function getWallList(
  tkc: string,
  branchId: string,
  userId: number,
  start: number,
  timeOffset: number,
  noOfRecords: number
) {
  return axios.post(GET_Wall_LIST, {
    timeOffset,
    start,
    noOfRecords,
    userId,
    branchId,
    tkc,
  })
}

export function uploadfile(fileName: string, base64: any) {
  return axios.post(Upload_File64, {
    fileName,
    base64,
  })
}

export function NewPost(
  tkc: string,
  branchId: string,
  userId: number,
  userName: string,
  visibility: number,
  expiration: number,
  comments: string,
  userProfile?: string
) {
  return axios.post(Create_Post, {
    userId,
    tkc,
    userName,
    visibility,
    expiration,
    comments,
    userProfile,
    branchId,
  })
}

export function showlike_user(
  tkc: string,
  userId: number,
  entityId: string,
  entityType: number,
  timeOffset: number
) {
  return axios.post(Show_LIKED_USer, {
    userId,
    tkc,
    entityId,
    entityType,
    timeOffset,
  })
}

export function LIKE_API(
  tkc: string,
  userId: number,
  entityId: string,
  entityType: number,
  userName: string,
  Isliked: boolean
) {
  let islikedapi: string = ''
  if (Isliked) {
    islikedapi = `${API_URL}/api/sa-rest/v1/revert/likes`
  } else {
    islikedapi = `${API_URL}/api/sa-rest/v1/addLikesToAssets`
  }

  return axios.post(islikedapi, {
    tkc,
    userId,
    entityId,
    entityType,
    userName,
  })
}

export function getpost_reply(
  tkc: string,
  userId: number,
  wallentryid: string,
  timeOffset: number
) {
  return axios.post(GET_POST_REPLY_DETail, {
    tkc,
    userId,
    wallentryid,
    timeOffset,
  })
}

export function COMMENT_LIKE_API(
  tkc: string,
  userId: number,
  entityId: number,
  entityType: number,
  userName: string,
  Isliked: boolean
) {
  let islikedapi: string = ''

  if (Isliked) {
    islikedapi = `${API_URL}/api/sa-rest/v1/revert/likes`
  } else {
    islikedapi = `${API_URL}/api/sa-rest/v1/addLikesToAssets`
  }

  return axios.post(islikedapi, {
    tkc,
    userId,
    entityId,
    entityType,
    userName,
    Isliked,
  })
}

export function ADD_COMMENT_API(
  userId: number,
  entityId: string,
  entityType: number,
  comment: string,
  userName: string,
  tkc: string,
  userProfile?: string
) {
  let totalDisLikes: number = 0
  let totalLikes: number = 0
  let isLiked: boolean = false
  let isDisLiked: boolean = false
  return axios.post(ADD_POST_COMMENT, {
    userId,
    entityId,
    entityType,
    comment,
    userName,
    userProfile,
    tkc,
    totalDisLikes,
    totalLikes,
    isLiked,
    isDisLiked,
  })
}

export function ADD_COMMENT_REPLY_API(
  userId: number,
  assetEntryId: string,
  commentId: number,
  entityType: number,
  replyText: string,
  userName: string,
  tkc: string,
  userProfile?: string
) {
  let totalDisLikes: number = 0
  let totalLikes: number = 0
  let isLiked: boolean = false
  let isDisLiked: boolean = false
  return axios.post(ADD_POST_COMMENT_REPLY, {
    userId,
    assetEntryId,
    commentId,
    entityType,
    replyText,
    userName,
    userProfile,
    tkc,
    totalDisLikes,
    totalLikes,
    isLiked,
    isDisLiked,
  })
}
