import axios from 'axios'
const BASE_API_URL = process.env.REACT_APP_API_URL
//=====================Country URL======================
export const GET_ALL_EMPLOYEE = `${BASE_API_URL}/Employee/GetEmployeeList` //Get Country Page
export const GET_ALL_EMPLOYEE_WEB_LIST = `${BASE_API_URL}/Employee//GetEmployeeWebList` //Get Country Page
export const CREATE_EMPLOYEE = `${BASE_API_URL}/Employee/AddEmployeePersonalDetails` //Get Country Page
export const GET_EMPLOYEE_BY_EMPLOYEE_ID = `${BASE_API_URL}/Employee/GetEmpByEmpID` //Get Country Page
export const ISACTIVE_EMPLOYEE = `${BASE_API_URL}/Employee/UpdateEmployeeIsActive` //Get Country Page
export const DELETE_EMPLOYEE = `${BASE_API_URL}/Employee/PostDeleteEmployee` //Get Country Page
export const UPDATE_EMPLOYEE_PERSONAL = `${BASE_API_URL}/Employee/UpdateEmployeePersonalDetails`
export const UPDATE_EMPLOYEE_ADDRESS = `${BASE_API_URL}/Employee/UpdateEmployeeAddress`
export const UPDATE_EMPLOYEE_ORGANIZATION = `${BASE_API_URL}/Employee/UpdateEmployeeOrganization`
// ========================My Profile Page API========================
export const GET_PERSONAL_BY_EMPLOYEE_ID = `${BASE_API_URL}/Employee/GetEmpPersnolByEmpID`
export const GET_ADDRESS_BY_EMPLOYEE_ID = `${BASE_API_URL}/Employee/GetEmpAddressByEmpID`
export const GET_ORGANIZATION_BY_EMPLOYEE_ID = `${BASE_API_URL}/Employee/GetEmpOrganizationByEmpID`
// ========================== Employee Page API=====================
export const GET_TECHNOLOGY_WITH_EMPLOYEE_ID = `${BASE_API_URL}/Employee/GetTechnologyWithEmployeeID`
export const ADD_TECHNOLOGY_BY_EMPLOYEE_ID = `${BASE_API_URL}/Employee/AddTechnologybyEmployeeID`
export const GET_PHOTO_BY_EMPLOYEE_ID = `${BASE_API_URL}/Employee/GetEmpPhotoByEmployeeID`

export function getAllEmployeeList() {
  return axios.get(GET_ALL_EMPLOYEE)
}
export function getAllEmployeeWebList() {
  return axios.get(GET_ALL_EMPLOYEE_WEB_LIST)
}

// ============ Use Employee Page =============
export function getTechnologyWithEmpId(employeeID: number) {
  return axios.post(GET_TECHNOLOGY_WITH_EMPLOYEE_ID, {employeeID})
}
export function AddTechnologyByEmpId(technologyID: string, employeeID: number) {
  return axios.post(ADD_TECHNOLOGY_BY_EMPLOYEE_ID, { technologyID, employeeID})
}

export function createEmployeePersonal(
  FirstName: string,
  MiddleName: string,
  LastName: string,
  Email: string,
  ContactNumber: string,
  BloodGroupID: number,
  GenderID: number,
  EmergenecyContactName: string,
  EmergencyContactNumber: string,
  EmergencyRelationID: number,
  BirthDate: string,
  NationalityID: number,
  AnniversaryDate: string,
  DeptID: number,
  OrgID: number,
  isActive: boolean,
  createBy: number,
  ipAddress: string
) {
  return axios.post(CREATE_EMPLOYEE, {
    FirstName,
    MiddleName,
    LastName,
    Email,
    ContactNumber,
    BloodGroupID,
    GenderID,
    EmergenecyContactName,
    EmergencyContactNumber,
    EmergencyRelationID,
    BirthDate,
    NationalityID,
    AnniversaryDate,
    DeptID,
    OrgID,
    isActive,
    createBy,
    ipAddress,
  })
}

export function UpdateEmployeePersonalDetails(
  employeeID: number,
  FirstName: string,
  MiddleName: string,
  LastName: string,
  Email: string,
  ContactNumber: string,
  BloodGroupID: number,
  GenderID: number,
  EmergenecyContactName: string,
  EmergencyContactNumber: string,
  EmergencyRelationID: number,
  BirthDate: string,
  NationalityID: number,
  AnniversaryDate: string,
  DeptID: number,
  OrgID: number,
  isActive: boolean,
  updateBy: number,
  ipAddress: string
) {
  return axios.post(UPDATE_EMPLOYEE_PERSONAL, {
    employeeID,
    FirstName,
    MiddleName,
    LastName,
    Email,
    ContactNumber,
    BloodGroupID,
    GenderID,
    EmergenecyContactName,
    EmergencyContactNumber,
    EmergencyRelationID,
    BirthDate,
    NationalityID,
    AnniversaryDate,
    DeptID,
    OrgID,
    isActive,
    updateBy,
    ipAddress,
  })
}

export function UpdateEmployeeAddressDetails(
  employeeID: number,
  perPincode: string,
  curntAddress1: string,
  curntAddress2: string,
  curntPincode: string,
  perAddress1: string,
  perAddress2: string,
  perCityID: number,
  perStateID: number,
  perCoutnryID: number,
  curntCityID: number,
  curntSateID: number,
  curntCountryID: number,
  updateBy: number,
  ipAddress: string
) {
  return axios.post(UPDATE_EMPLOYEE_ADDRESS, {
    employeeID,
    perPincode,
    curntAddress1,
    curntAddress2,
    curntPincode,
    perAddress1,
    perAddress2,
    perCityID,
    perStateID,
    perCoutnryID,
    curntCityID,
    curntSateID,
    curntCountryID,
    updateBy,
    ipAddress,
  })
}

export function UpdateEmployeeOrganizationDetails(
  employeeID: number,
  designationID: number,
  roleID: number,
  joinDate: string,
  resignDate: string,
  shiftID: number,
  branchID: number,
  yearlyPaidLeave: number,
  salary: number,
  organizationLeaveDate: string,
  iPAddress: string,
  updateBy: number
) {
  return axios.post(UPDATE_EMPLOYEE_ORGANIZATION, {
    employeeID,
    designationID,
    roleID,
    joinDate,
    resignDate,
    shiftID,
    branchID,
    yearlyPaidLeave,
    salary,
    organizationLeaveDate,
    iPAddress,
    updateBy,
  })
}

export function getEmpByEmpeID(employeeID: number) {
  return axios.post(GET_EMPLOYEE_BY_EMPLOYEE_ID, {employeeID})
}

export function isActiveEmployee(employeeID: number, isActive: boolean) {
  return axios.post(ISACTIVE_EMPLOYEE, {employeeID, isActive})
}
export function deleteEmployee(employeeID: number) {
  return axios.post(DELETE_EMPLOYEE, {employeeID})
}

// =======================My Profile=======================

export function employeePersonal(employeeID: number) {
  return axios.post(GET_PERSONAL_BY_EMPLOYEE_ID, {employeeID})
}
export function employeeAddress(employeeID: number) {
  return axios.post(GET_ADDRESS_BY_EMPLOYEE_ID, {employeeID})
}
export function employeeOrganization(employeeID: number) {
  return axios.post(GET_ORGANIZATION_BY_EMPLOYEE_ID, {employeeID})
}

export function employeePhotoApi(employeeID: number) {
  return axios.post(GET_PHOTO_BY_EMPLOYEE_ID, {employeeID})
}