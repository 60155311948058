/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()
  // const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  // alert(user.roleID);

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem 
        to='/builders'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Builder Layout'
        fontIcon='bi-layers'
      /> */}

      {/* Self Prictice Using Templete=============  */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>

      <div className={user.roleID === 4 ? 'hideleftmenu' : 'text-info text-center'}>
        <AsideMenuItemWithSub
          to='/location'
          title='Location'
          icon='/media/icons/duotune/maps/map007.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem to='/location/country' title='Country' hasBullet={true} />
          <AsideMenuItem to='/location/state' title='State' hasBullet={true} />
          <AsideMenuItem to='/location/city' title='City' hasBullet={true} />
        </AsideMenuItemWithSub>
      </div>

      {/* ==============================Product==================================== */}
      <div className={user.roleID === 4 ? 'hideleftmenu' : 'text-info text-center'}>
        <AsideMenuItemWithSub
          to='/product'
          title='Product'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen025.svg'
        >
          <AsideMenuItem to='/product/productcategory' title='Product Category' hasBullet={true} />
          <AsideMenuItem
            to='/product/productmakemst'
            title='Product Make Master'
            hasBullet={true}
          />
          <AsideMenuItem to='/product/producmst' title='Product Master' hasBullet={true} />
        </AsideMenuItemWithSub>
      </div>

      {/* ========MasterListItem==--------------------------------------------------------------======== */}
      <div className={user.roleID === 4 ? 'hideleftmenu' : 'text-info text-center'}>
        <AsideMenuItemWithSub
          to='/master'
          title='Masters'
          icon='/media/icons/duotune/communication/com014.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem to='/master/currency' title='Currency' hasBullet={true} />
          <AsideMenuItem to='/master/converter' title='Currency Converter' hasBullet={true} />
          <AsideMenuItem to='/master/department' title='Department' hasBullet={true} />
          <AsideMenuItem to='/master/designation' title='Designation' hasBullet={true} />
          <AsideMenuItem to='/master/doctype' title='Document Type' hasBullet={true} />
          <AsideMenuItem to='/master/educategory' title='Education Category' hasBullet={true} />
          <AsideMenuItem to='/master/edudepartment' title='Education Department' hasBullet={true} />
          <AsideMenuItem to='/master/nationality' title='Nationality' hasBullet={true} />
          <AsideMenuItem to='/master/shift' title='Shift' hasBullet={true} />
          <AsideMenuItem to='/master/skill' title='Skill' hasBullet={true} />
          <AsideMenuItem to='/master/technology' title='Technology' hasBullet={true} />
          <AsideMenuItem to='/master/rolemodulemap' title='Role Module Map' hasBullet={true} />
          <AsideMenuItem to='/master/gsttax' title='GST Tax' hasBullet={true} />
        </AsideMenuItemWithSub>
      </div>

      {/* =================-------------------------------------------------------------=============== */}
      <div className={user.roleID === 4 ? 'hideleftmenu' : 'text-info text-center'}>
        <AsideMenuItemWithSub
          to='/organization'
          title='Manage Organization'
          icon='/media/icons/duotune/finance/fin001.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem to='/organization/organization' title='Organization' hasBullet={true} />
          <AsideMenuItem to='/organization/bank' title='Organization Bank' hasBullet={true} />
          <AsideMenuItem to='/organization/branch' title='Branch' hasBullet={true} />
          <AsideMenuItem to='/organization/leavetype' title='Leave Type' hasBullet={true} />
          {/* <AsideMenuItem to='/organization/leaves' title='Leave' hasBullet={true} /> */}
          <AsideMenuItem to='/organization/leavepolicy' title='Leave Policy' hasBullet={true} />
          <AsideMenuItem
            to='/organization/document'
            title='Organization Document'
            hasBullet={true}
          />
          <AsideMenuItem to='/organization/role' title='Role' hasBullet={true} />
          <AsideMenuItem to='/organization/module' title='Module' hasBullet={true} />
          <AsideMenuItem
            to='/organization/holiday'
            title='Holiday'
            icon='/media/icons/duotune/general/gen014.svg'
            fontIcon='bi-layers'
          ></AsideMenuItem>
        </AsideMenuItemWithSub>
      </div>

      {/* ===========---------------------------------------------------========================== */}
      <div className={user.roleID === 4 ? 'hideleftmenu' : 'text-info text-center'}>
        <AsideMenuItemWithSub
          to='/hremp'
          title='HR Employee'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem
            to='/hremp/list'
            title='Employee'
            icon='/media/icons/duotune/general/gen049.svg'
            fontIcon='bi-layers'
          ></AsideMenuItem>
          <AsideMenuItem to='/hremp/attendance' title='Attendance' hasBullet={true} />
          <AsideMenuItem to='/hremp/leaverqst' title='Leave Request' hasBullet={true} />
          <AsideMenuItem to='/hremp/moment' title='Movement' hasBullet={true} />
          <AsideMenuItem to='/hremp/empdailyactivity' title='Daily Activity' hasBullet={true} />
        </AsideMenuItemWithSub>
      </div>

      {/* ===========---------------------------------------------------========================== */}

      <AsideMenuItemWithSub
        to='/employee'
        title='Personal Details'
        icon='/media/icons/duotune/general/gen049.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem
          to='/employee/leave/leave'
          title='My Leave'
          icon='/media/icons/duotune/general/gen014.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>

        <AsideMenuItem
          to='/employee/leave/reqleave'
          title='My Leave Request'
          icon='/media/icons/duotune/communication/com005.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
        {/* <div className={user.roleID === 4 ? "showleftmenu" : "hideleftmenu"}> */}
        <AsideMenuItem
          to='/employee/attendance'
          title='My Attendance'
          icon='/media/icons/duotune/general/gen014.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
        {/* </div> */}

        <AsideMenuItem
          to='/employee/salarydetails'
          title='My Salary Details'
          icon='/media/icons/duotune/finance/fin010.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>

        <AsideMenuItem
          to='/employee/documents'
          title='My Documents'
          icon='/media/icons/duotune/files/fil006.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
        {/* <AsideMenuItem
          to='/profile'
          title='My Profile'
          icon='/media/icons/duotune/communication/com005.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem> */}
        <AsideMenuItem
          to='/employee/changepassword'
          title='Change Password'
          icon='/media/icons/duotune/general/gen051.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
      </AsideMenuItemWithSub>
      {/* ===========-------------------View--------------------------------========================== */}

      <AsideMenuItemWithSub
        to='/view'
        title='Personal Details'
        icon='/media/icons/duotune/general/gen056.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem
          to='/view/viewemployee'
          title='Employee List'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
        <AsideMenuItem
          to='/view/holiday'
          title='Holiday List'
          icon='/media/icons/duotune/general/gen058.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
        <AsideMenuItem
          to='/view/notifications'
          title='Notifications'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
        <AsideMenuItem
          to='/view/events'
          title='Events'
          icon='/media/icons/duotune/general/gen058.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
        <AsideMenuItem
          to='/view/birthday'
          title='Today birthday'
          icon='/media/icons/duotune/general/gen058.svg'
          fontIcon='bi-layers'
        ></AsideMenuItem>
      </AsideMenuItemWithSub>

      {/* =======================Project============================= */}

      <AsideMenuItemWithSub
        to='/projects'
        title='Project'
        icon='/media/icons/duotune/files/fil011.svg'
        fontIcon='bi-layers'
      >
        <div className={user.roleID === 4 ? 'hideleftmenu' : 'text-info text-center'}>
          <AsideMenuItem to='/projects/client' title='Client' hasBullet={true} />
          <AsideMenuItem to='/projects/projectdtls' title='Project' hasBullet={true} />
        </div>
        {/* <div className={user.designationID !== 8  ? 'hideleftmenu' : 'text-info text-center'}> */}
        <AsideMenuItem to='/projects/task' title='Project Task' hasBullet={true} />
        {/* </div> */}
        <AsideMenuItem to='/projects/mytask' title='My Task' hasBullet={true} />
        <AsideMenuItem to='/projects/activity' title='My Activity' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* ------------------------ For Vendor ----------------------- */}
      <AsideMenuItem
        to='/vendor'
        title='Vendor'
        icon='/media/icons/duotune/communication/com006.svg'
      />
      {(user.roleID == 1 || user.roleID == 2) && (
        <AsideMenuItem
          to='/web-enquiry'
          title='Web Enquiry'
          icon='/media/icons/duotune/communication/com005.svg'
        />
      )}

      {/* =================--------------------------------------------------------========================= */}
      {/* <div className={user.roleID === 4 ? 'hideleftmenu' : 'text-info text-center'}>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
          </div>
        </div> */}

      {/* <div  className={CheckConfig('WALL') >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/wall'
        icon='/media/icons/duotune/general/gen025.svg'
        title='Wall'
        fontIcon='bi-layers'
      />
      </div> */}
      {/* <AsideMenuItemWithSub
          to='/crafted/widgets'
          title='Widgets'
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </AsideMenuItemWithSub>
      </div> */}
      {/* <div  className={CheckConfig('PEOPLE')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/people'
        icon='/media/icons/duotune/general/gen019.svg'
        title='People'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('TIMETABLE')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/timetable'
        icon='/media/icons/duotune/general/gen014.svg'
        title='Timetable'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('HOMEWORK')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/homework'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Homework'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('QUIZ')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/quiz'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Quiz'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('QUESTIONNAIR')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/questions'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Questions'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('POLLS')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/polls'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Polls'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('CHAT')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/chat'
        icon='/media/icons/duotune/communication/com003.svg'
        title='chat'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('FORUM')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/forum'
        icon='/media/icons/duotune/general/gen019.svg'
        title='forum'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('MEET')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/meet'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Meet'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('HOLIDAY')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/holidays'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Holiday'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('LIBRARY')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/library'
        icon='/media/icons/duotune/general/gen054.svg'
        title='lIBRARY'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('COURSEWORK')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/coursework'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Cource Work'
        fontIcon='bi-layers'
      />
      </div>
      <div  className={CheckConfig('LIVE')  >-1 ? "showleftmenu":"hideleftmenu"}>
      <AsideMenuItem 
        to='/live'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Live'
        fontIcon='bi-layers'
      />
      </div> */}
      {/* <div className={user.roleID === 4 ? 'hideleftmenu' : 'text-info text-center'}> */}
      {/* <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
          </div>
        </div> */}

      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      ></AsideMenuItemWithSub> */}

      {/* <AsideMenuItemWithSub
          to='/crafted/pages'
          title='Pages'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
            <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <AsideMenuItem
              to='/crafted/pages/profile/campaigns'
              title='Campaigns'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/crafted/pages/profile/documents'
              title='Documents'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>

          <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
            <AsideMenuItem
              to='/crafted/pages/wizards/horizontal'
              title='Horizontal'
              hasBullet={true}
            />
            <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/crafted/accounts'
          title='Accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/error'
          title='Errors'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen040.svg'
        >
          <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/crafted/widgets'
          title='Widgets'
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </AsideMenuItemWithSub>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
          </div>
        </div>
        <AsideMenuItemWithSub
          to='/apps/chat'
          title='Chat'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/com012.svg'
        >
          <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </AsideMenuItemWithSub>
        <div className='menu-item'>
          <div className='menu-content'>
            <div className='separator mx-1 my-4'></div>
          </div>
        </div>
        <div className='menu-item'>
          <a
            target='_blank'
            className='menu-link'
            href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          >
            <span className='menu-icon'>
              <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
            </span>
            <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
          </a>
        </div> */}
      {/* </div>  */}
    </>
  )
}
