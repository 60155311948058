import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {IMyOrganizationModel} from '../../../models/employee_model/MyAddressModel'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {employeeOrganization} from '../../../modules/employee/empcrud/EmployeeCRUD'

interface MyOrganization {
  loading: boolean
  myOrgData: IMyOrganizationModel
}

const MyOrganization = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [state, setState] = useState<MyOrganization>({
    loading: false,
    myOrgData: {} as IMyOrganizationModel,
  })

  useEffect(() => {
    setState({...state, loading: true})
    setTimeout(() => {
      getMyOrganizationData()
    }, 100)
  }, [])

  function getMyOrganizationData() {
    employeeOrganization(user.employeeID)
      .then((response) => {
        const orgData = response.data
        setState({...state, myOrgData: orgData, loading: false})
      })
      .catch((error) => {
        alert(error)
        setState({...state, myOrgData: {} as IMyOrganizationModel, loading: false})
      })
  }

  const {myOrgData} = state

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      {/* <div className='card-header cursor-pointer'>
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0'>Profile Details</h3>
      </div>
      <Link to='/crafted/account/settings' className='btn btn-primary align-self-center'>
        Edit Profile
      </Link>
    </div> */}

      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Branch Name</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myOrgData.branchName == '' ? '-' : myOrgData.branchName}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Designation Name</label>
          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6'>{myOrgData.desgnationName == '' ? '-' : myOrgData.desgnationName}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Shift Name</label>
          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6'>{myOrgData.shiftName == '' ? '-' : myOrgData.shiftName}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Regining Date</label>
          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6'>{myOrgData.resignDate == '' ? '-' : myOrgData.resignDate}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Organization Leave Date</label>
          <div className='col-lg-9 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>{myOrgData.organizationLeaveDate == '' ? '-' : myOrgData.organizationLeaveDate}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Salary</label>
          <div className='col-lg-9'>
            <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
              {myOrgData.salary == '' ? '-' : myOrgData.salary}
            </a>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Yearly Paid Leave</label>
          <div className='col-lg-9'>
            <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
              {myOrgData.yearlyPaidLeave == 0 ? 0 : myOrgData.yearlyPaidLeave}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyOrganization
