import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import {useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import Pagination from '../../../../Pagination'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {IEmpDocMapWebModel} from '../../../models/master_model/EmpDocMapModel'
import {getEmpDocMap, isActiveEmpDocMap} from '../../../modules/masters/EmpDocMapCRUD'

type Props = {}
interface IEmpDocMap {
  loading: boolean
  empDocMapData: IEmpDocMapWebModel[]
  selDocMapData: IEmpDocMapWebModel
  selDocMapId: number
  activeID: number
  activeType: any
}
const MyDocumentMap: React.FC<Props> = () => {
  const location = useLocation()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [state, setState] = useState<IEmpDocMap>({
    loading: false,
    empDocMapData: [] as IEmpDocMapWebModel[],
    selDocMapData: {} as IEmpDocMapWebModel,
    selDocMapId: 0,
    activeID: 0,
    activeType: false,
  })
  useEffect(() => {
    setState({...state, loading: true})
    setTimeout(() => {
      getAllEmpDocMapData()
    }, 100)
  }, [])

  function getAllEmpDocMapData() {
    getEmpDocMap(user.employeeID)
      .then((response) => {
        console.log(response)
        const responseData = response.data.responseObject
        setState({...state, empDocMapData: responseData, loading: false})
      })
      .catch((error) => {
        toast.error(`${error}`, {position: 'top-center'})
        setState({...state, empDocMapData: [], loading: false})
      })
  }

  // =================Is Active Function Model Call==============

  const [showActive, setShowActive] = useState(false)
  const handleCloseActive = () => setShowActive(false)

  function handleShowActive(event: any) {
    const Cid = event.target.id
    const tmpIsActive = event.target.checked
    setState({
      ...state,
      activeID: Cid,
      activeType: tmpIsActive,
      loading: false,
    })
    setShowActive(true)
  }

  function checkedFunction(temEmpId: number, temIsAct: boolean) {
    isActiveEmpDocMap(temEmpId, temIsAct)
      .then((response) => {
        getAllEmpDocMapData()
        setShowActive(false)
      })
      .catch((error) => {
        toast.error(`${error}`, {position: 'top-center'})
        setShowActive(false)
      })
  }

  const [show, setShow] = useState(false)

  function handleClose() {
    setShow(false)
  }

  function handleShow(data: IEmpDocMapWebModel) {
    setState({...state, selDocMapData: data})
    setShow(true)
  }

  // ================Pagination============
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage, setPostsPerPage] = useState(10)
  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts: IEmpDocMapWebModel[] = state.empDocMapData.slice(
    firstPostIndex,
    lastPostIndex
  )

  return (
    <>
      <div className={`card `}>
        {/* begin::Header */}
        {/* <div className='card-header border-0 py-2' style={{backgroundColor: '#2a3952'}}>
          <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Employee Document Map</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Over 500 Employee Document Map</span>
          </h3>

          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Link
              to='/mcreate/empdocmap'
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Add New
            </Link>
          </div>
        </div> */}
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder fs-5'>
                  <th className='min-w-140px'>Document Type</th>
                  <th className='min-w-140px'>Document Number</th>
                  <th className='min-w-140px'>Description</th>
                  <th className='min-w-25px'>Active</th>
                  <th className='min-w-100px text-end'>View</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {currentPosts.length > 0 &&
                  currentPosts.map((data, index) => {
                    return (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark text-hover-primary fs-6'>
                                {data.documentTypeName}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark text-hover-primary fs-6'>
                                {data.docNumber}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a href='#' className='text-dark text-hover-primary d-block fs-6'>
                            {data.description}
                          </a>
                        </td>
                        <td>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={`${data.employeeDocID}`}
                              checked={data.isActive}
                              onChange={(e) => handleShowActive(e)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon btn-bg-light bg-hover-success text-hover-inverse-success btn-sm me-1 text-success text-hover-light'
                              onClick={() => handleShow(data)}
                            >
                              <span className='fa fa-eye lg'></span>
                            </span>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                {/* =================== Loading get Api Data ============== */}
                <tr className={state.loading === true ? '' : 'd-none'}>
                  <td colSpan={6}>
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                      <div
                        className='spinner-border'
                        style={{width: '3rem', height: '3rem'}}
                        role='status'
                      >
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
            totalPosts={state.empDocMapData.length}
            postPerPage={postsPerPage}
            setCurrentPages={setCurrentPage}
            currentPage={currentPage}
          ></Pagination>
        </div>
      </div>
      {/* ===================Is Active Model===================== */}
      <Modal show={showActive} onHide={handleCloseActive} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Active Employee Document Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.activeType === false ? (
            <h4>Are you sure you want to deactive selected record?</h4>
          ) : (
            <h4>Are you sure you want to active selected record?</h4>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={state.activeType === false ? 'danger' : 'success'}
            onClick={() => checkedFunction(state.activeID, state.activeType)}
          >
            {state.activeType === false ? 'Deactive' : 'Active'}
          </Button>
          <Button variant='secondary' onClick={handleCloseActive}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ==========================View Employee Document Model======================== */}
      <Modal size='lg' scrollable={true} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Document Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body pt-2'>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Document Type :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selDocMapData.documentTypeName}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Document Number :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>{state.selDocMapData.docNumber}</span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Description :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selDocMapData.description}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant='primary' onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MyDocumentMap
