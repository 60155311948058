import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap-v5'
import {Link, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {IEmployeeBankDetailsWebModel} from '../../../models/employee_model/EmployeeBankDetailsModel'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {
  deleteEmpBankDetails,
  getAllEmpBankDetails,
  getEmpBankDetailsByEmpID,
  isActiveEmpBankDetails,
} from '../../../modules/employee/empcrud/EmpBankDetailsCRUD'
import {KTSVG} from '../../../../_ecadme/helpers'

type Props = {}

interface IEmpBankDetails {
  loading: boolean
  empBankData: IEmployeeBankDetailsWebModel[]
  selEmpBankData: IEmployeeBankDetailsWebModel
  newEmpID: number
}

const MyBankDetails: React.FC<Props> = () => {
  const location = useLocation()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [state, setState] = useState<IEmpBankDetails>({
    loading: false,
    empBankData: [] as IEmployeeBankDetailsWebModel[],
    selEmpBankData: {} as IEmployeeBankDetailsWebModel,
    newEmpID: 0,
  })

  const [show, setShow] = useState(false)

  function handleClose() {
    setShow(false)
  }

  function handleShow(data: IEmployeeBankDetailsWebModel) {
    setState({...state, selEmpBankData: data})
    setShow(true)
  }

  useEffect(() => {
    setState({...state, loading: false})
    setTimeout(() => {
      getAllEmpBankDetailsDataByEmpID()
    }, 100)
  }, [])

  function getAllEmpBankDetailsDataByEmpID() {
    getEmpBankDetailsByEmpID(user.employeeID)
      .then((response) => {
        const responseData = response.data.responseObject
        setState({...state, empBankData: responseData, loading: false})
      })
      .catch((error) => {
        alert(error)
        setState({...state, empBankData: [], loading: false})
      })
  }

  function checkedFunction(event: any) {
    const temEmpBankId = event.target.id
    const temEmpBankIsAct = event.target.checked
    const active = window.confirm('Are You Sure To Changes??')
    if (active) {
      isActiveEmpBankDetails(temEmpBankId, temEmpBankIsAct)
        .then((response) => {
          getAllEmpBankDetailsDataByEmpID()
        })
        .catch((error) => {
          alert(error)
        })
    } else {
      return
    }
  }

  function deleteEmployeeBankDetails(empbankId: number) {
    const result = window.confirm('Are You Sure To Delete')
    if (result) {
      deleteEmpBankDetails(empbankId)
        .then((response) => {
          toast.success('Deleted Successfully', {position: 'top-center'})
          getAllEmpBankDetailsDataByEmpID()
        })
        .catch((error) => {
          alert(error)
        })
    } else {
      return
    }
  }

  return (
    <>
      <div className={`card `}>
        {/* begin::Header */}
        {/* <div className='card-header border-0 pt-5'> */}
          {/* <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Employee Bank Details</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Over 500 Employee Bank</span>
          </h3> */}
          {/* <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <Link to='/employees/create/bank' className='btn btn-sm btn-light-primary'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Add New
            </Link>
          </div> */}
        {/* </div> */}
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>Account Number</th>
                  <th className='min-w-140px'>Bank Name</th>
                  <th className='min-w-140px'>Branch Name</th>
                  <th className='min-w-140px'>PFA Number</th>
                  <th className='min-w-25px'>isActive</th>
                  <th
                    className={
                      location.pathname === '/employee/details/bank' ||
                      location.pathname === '/profile/mybank'
                        ? 'd-none'
                        : 'min-w-100px text-end'
                    }
                  >
                    View / Edit / Delete
                  </th>
                  <th
                    className={
                      location.pathname === '/employee/details/bank' ||
                      location.pathname === '/profile/mybank'
                        ? 'min-w-25px text-end pe-5'
                        : 'd-none'
                    }
                  >
                    View
                  </th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {state.empBankData.length > 0 &&
                  state.empBankData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                                {data.accountNumber}
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6'
                          >
                            {data.bankName}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6'
                          >
                            {data.branchName}
                          </a>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bolder text-hover-primary d-block fs-6'
                          >
                            {data.pfaNumber}
                          </a>
                        </td>
                        <td>
                          <div className='form-check form-switch'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id={`${data.employeeBankID}`}
                              checked={data.isActive}
                              onChange={(e) => checkedFunction(e)}
                            />
                          </div>
                        </td>
                        <td
                          className={
                            location.pathname === '/employee/details/bank' ||
                            location.pathname === '/profile/mybank'
                              ? ''
                              : 'd-none'
                          }
                        >
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon btn-bg-light bg-hover-success text-hover-inverse-success btn-sm me-1 text-success text-hover-light'
                              onClick={() => handleShow(data)}
                            >
                              <span className='fa fa-eye lg'></span>
                              {/* <KTSVG
                                path='/media/icons/duotune/art/art008.svg'
                                className='svg-icon-3'
                              /> */}
                            </span>
                          </div>
                        </td>
                        <td
                          className={
                            location.pathname === '/employee/details/bank' ||
                            location.pathname === '/profile/mybank'
                              ? 'd-none'
                              : ''
                          }
                        >
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon btn-bg-light bg-hover-success text-hover-inverse-success btn-sm me-1 text-success text-hover-light'
                              onClick={() => handleShow(data)}
                            >
                              <span className='fa fa-eye lg'></span>
                              {/* <KTSVG
                                path='/media/icons/duotune/art/art008.svg'
                                className='svg-icon-3'
                              /> */}
                            </span>
                            <Link
                              to={`/employee/edit/bank/${data.employeeBankID}`}
                              className='btn btn-icon btn-bg-light bg-hover-primary text-hover-inverse-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3 svg-icon-primary'
                              />
                            </Link>
                            <div
                              onClick={(e) => deleteEmployeeBankDetails(data.employeeBankID)}
                              className='btn btn-icon btn-bg-light bg-hover-danger text-hover-inverse-danger btn-sm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3 svg-icon-danger'
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal size='lg' scrollable={true} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card-body pt-2'>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Bank Name :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>{state.selEmpBankData.bankName}</span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Branch Name :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.branchName}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                IFSC Code :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>{state.selEmpBankData.ifscCode}</span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Account Number :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.accountNumber}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                Account Name :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.accountName}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                PFA Company Name :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.pfaCompanyName}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                PFAUN Number :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.pfaunNumber}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                PFA Number :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.pfaNumber}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                PFA Start Date :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.pfaStartDate}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                PFA End Date :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.pfaEndDate}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                ESIC Number :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.esicNumber}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                ESIC Start Date :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.esicStartDate}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
            <div className='row g-4'>
              <span className='col-6 col-sm-4 text-gray-800 text-hover-primary fw-bold'>
                ESIC Expire Date :
              </span>
              <span className='col-6 col-sm-4 fw-bolder fs-4'>
                {state.selEmpBankData.esicExpDate}
              </span>
            </div>
            <div className='w-100 border-bottom d-md-block my-1'></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant='primary' onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MyBankDetails
