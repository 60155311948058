import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_ecadme/layout/core'
import MyBankDetails from '../../pages/employee/myprofile/MyBank'
import MyAddress from '../../pages/employee/myprofile/MyAddress'
import MyOrganization from '../../pages/employee/myprofile/MyOrganization'
import {MyPersonal} from '../../pages/employee/myprofile/MyPersonal'
import { MyProfileHeader } from '../../pages/employee/myprofile/MyProfileHeader'
import MyEducationDetails from '../../pages/employee/myprofile/MyEducation'
import MyDocumentMap from '../../pages/employee/myprofile/MyDocumentMap'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Presonal',
    path: '/profile/personal',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MyProfilePage: React.FC = () => {
  return (
    <>
      <MyProfileHeader />
      <Switch>
        <Route path='/profile/personal'>
          <PageTitle>My Personal</PageTitle>
          <MyPersonal />
        </Route>
        <Route path='/profile/address'>
          <PageTitle>My Address</PageTitle>
          <MyAddress />
        </Route>
        <Route path='/profile/myorganization'>
          <PageTitle>My Organization</PageTitle>
          <MyOrganization />
        </Route>
        <Route path='/profile/mybank'>
          <PageTitle>My Bank</PageTitle>
          <MyBankDetails />
        </Route>
        <Route path='/profile/myeducation'>
          <PageTitle>My Education</PageTitle>
          <MyEducationDetails />
        </Route>
        <Route path='/profile/mydocument'>
          <PageTitle>Documents</PageTitle>
          <MyDocumentMap />
        </Route>
        <Redirect from='/profile' exact={true} to='/profile/personal' />
        <Redirect to='/profile/personal' />
      </Switch>
    </>
  )
}

export default MyProfilePage
