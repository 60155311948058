import {Suspense, lazy} from 'react'
import 'react-toastify/dist/ReactToastify.css'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_ecadme/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import Wall from '../pages/wall/Wall'
import Wallcomment from '../pages/wall/WallComment'
import MyProfilePage from '../modules/employee/MyProfilePage'
import BankMasterPage from '../modules/organization/bank-master-page/BankMasterPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const LocationPage = lazy(() => import('../modules/location/LocationPage'))
  const LocationCreatePage = lazy(() => import('../modules/location/LocationCreatePage'))
  const LocationEditPage = lazy(() => import('../modules/location/LocationEditPage'))
  const MasterPage = lazy(() => import('../modules/masters/master/MasterPage'))
  const MasterCreatePage = lazy(() => import('../modules/masters/master/MasterCreatePage'))
  const MasterEditPage = lazy(() => import('../modules/masters/master/MasterEditPage'))
  const ProductCategoryMasterPage = lazy(
    () => import('../modules/product-page/product-catagory-master-page/ProductCategoryMasterPage')
  )
  const ProductMakeMasterPage = lazy(
    () => import('../modules/product-page/product-make-master-page/ProductMakeMasterPage')
  )
  const ProductMasterPage = lazy(
    () => import('../modules/product-page/Product-master-page/ProductMasterPage')
  )

  const OrganizationPage = lazy(
    () => import('../modules/organization/organizations/OrganizationPage')    
  )
  const OrganizationCreatePage = lazy(
    () => import('../modules/organization/organizations/OrganizationCreatePage')
  )
  const OrganizationEditPage = lazy(
    () => import('../modules/organization/organizations/OrganizationEditPage')
  )
  const WebEnquiryMasterPage = lazy(
    () => import('../modules/web-enquiry/WebEnquiryMasterPage')
  )
 
  const EmployeePage = lazy(() => import('../modules/employee/EmployeePage'))
  const EmployeeProfilePage = lazy(() => import('../modules/employee/EmployeeProfilePage'))
  const EmployeeDataCreatePage = lazy(() => import('../modules/employee/EmployeeDataCreatePage'))
  const EmployeeDataEditPage = lazy(() => import('../modules/employee/EmployeeDataEditPage'))
  const ClientPage = lazy(() => import('../modules/project/ProjectPage'))
  const CreateClientPage = lazy(() => import('../modules/project/CreateProjectPage'))
  const EditClientPage = lazy(() => import('../modules/project/EditProjectPage'))
  const EmpPage = lazy(() => import('../modules/hr_emp/EmpPage'))
  const EmpCreatePage = lazy(() => import('../modules/hr_emp/EmpCreatePage'))
  const EmpEditPage = lazy(() => import('../modules/hr_emp/EmpEditPage'))
  const ViewEmployeePage = lazy(() => import('../modules/employee/ViewEmployeePage'))
  const VendorMasterPage = lazy(() => import('../modules/vendor-page/VendorMasterPage'))
  const BankMasterPage = lazy(() => import('../modules/organization/bank-master-page/BankMasterPage'))


  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path='/dashboard' component={DashboardWrapper} />
          <Route path='/builder' component={BuilderPageWrapper} />
          <Route path='/crafted/pages/profile' component={ProfilePage} />
          <Route path='/wall' component={Wall} />
          <Route path='/wallcomment/:wid' component={Wallcomment} />
          <Route path='/crafted/pages/wizards' component={WizardsPage} />
          <Route path='/crafted/widgets' component={WidgetsPage} />
          <Route path='/crafted/account' component={AccountPage} />
          <Route path='/apps/chat' component={ChatPage} />
          <Route path='/menu-test' component={MenuTestPage} />
          <Route path='/location' component={LocationPage} />
          <Route path='/create' component={LocationCreatePage} />
          <Route path='/edit' component={LocationEditPage} />
          <Route path='/master' component={MasterPage} />
          <Route path='/mcreate' component={MasterCreatePage} />
          <Route path='/medit' component={MasterEditPage} />
          <Route path='/product/productcategory' component={ProductCategoryMasterPage} />
          <Route path='/product/productmakemst' component={ProductMakeMasterPage} />
          <Route path='/product/producmst' component={ProductMasterPage} />
          <Route path='/organization' component={OrganizationPage} />
          {/* <Route path='/bank' component={BankMasterPage} /> */}
          <Route path='/orgcreate' component={OrganizationCreatePage} />
          <Route path='/orgedit' component={OrganizationEditPage} />
          <Route path='/employee' component={EmployeePage} />
          <Route path='/employee/details' component={EmployeeProfilePage} />
          <Route path='/employees/create' component={EmployeeDataCreatePage} />
          <Route path='/employees/edit' component={EmployeeDataEditPage} />
          <Route path='/profile' component={MyProfilePage} />
          <Route path='/projects' component={ClientPage} />
          <Route path='/procreate' component={CreateClientPage} />
          <Route path='/proedit' component={EditClientPage} />
          <Route path='/hremp' component={EmpPage} />
          <Route path='/hrempcreate' component={EmpCreatePage} />
          <Route path='/hrempedit' component={EmpEditPage} />
          <Route path='/view' component={ViewEmployeePage} />
          <Route path='/vendor' component={VendorMasterPage} />
          <Route path='/web-enquiry' component={WebEnquiryMasterPage} />

          <Redirect from='/' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
