import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {IMyPersonalModel} from '../../../models/employee_model/MyAddressModel'
import {RootState} from '../../../../setup'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {employeePersonal} from '../../../modules/employee/empcrud/EmployeeCRUD'
import { useParams } from 'react-router-dom'

interface IMyProfile {
  loading: boolean
  myPersonalData: IMyPersonalModel
}

export function MyPersonal() {
  const {employeeID} = useParams<{employeeID: string}>()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [state, setState] = useState<IMyProfile>({
    loading: false,
    myPersonalData: {} as IMyPersonalModel,
  })

  useEffect(() => {
    setState({...state, loading: true})
    setTimeout(() => {
      // let empID = localStorage.getItem('editEmpID')!
      // let finalempID: number = JSON.parse(empID)
      // getMyPersonalData(finalempID)
      getMyPersonalData()
    }, 100)
  }, [])

  
  function getMyPersonalData() {
    employeePersonal(parseInt(employeeID))
      .then((response) => {
        const personData = response.data
        setState({...state, myPersonalData: personData, loading: false})
      })
      .catch((error) => {
        alert(error)
        setState({...state, myPersonalData: {} as IMyPersonalModel, loading: false})
      })
  }
  
  const {myPersonalData} = state

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      {/* <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
        <Link to='/crafted/account/settings' className='btn btn-primary align-self-center'>
          Update Profile
        </Link>
      </div> */}
      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Full Name</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>
              {myPersonalData.firstName + ' ' + myPersonalData.lastName}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Employee Code</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myPersonalData.employeeCode}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Organization Name</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myPersonalData.organizationName}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Department Name</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myPersonalData.departmentName}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Gender</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myPersonalData.genderName}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Blood Group</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myPersonalData.bloodGroupName == '' ? '-' : myPersonalData.bloodGroupName}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Nationality</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myPersonalData.nationalityName == '' ? '-' : myPersonalData.nationalityName}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Email</label>
          <div className='col-lg-9 fv-row'>
            <span className='fw-bold fs-6'>{myPersonalData.email == '' ? '-' : myPersonalData.email}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Contact Number</label>
          <div className='col-lg-9 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>{myPersonalData.contactNumber == 0 ? '-' : myPersonalData.contactNumber}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Emergency Contact Number</label>
          <div className='col-lg-9'>
            <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
              {myPersonalData.emergencyContactNumber == 0 ? '-' : myPersonalData.emergencyContactNumber}
            </a>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Emergency Contact Name</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>
              {myPersonalData.emergenecyContactName == '' ? '-' : myPersonalData.emergenecyContactName}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-3 fw-bold text-muted'>Birth Date</label>
          <div className='col-lg-9'>
            <span className='fw-bolder fs-6 text-dark'>{myPersonalData.birthDate == '' ? '-' : myPersonalData.birthDate}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-3 fw-bold text-muted'>Anniversary Date</label>
          <div className='col-lg-9'>
            <span className='fw-bold fs-6'>{myPersonalData.anniversaryDate == '' ? '-' : myPersonalData.anniversaryDate}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
